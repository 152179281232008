import { $post } from '@/api/http.js'
import common from '@/api/common.js'
import tool from '@/util/tool.js'
class SaasSet{
    /**
     * 获取网站信息
     * @param {object}} fn
     */
    getWebSite (fn) {
        $post("getWebSite",{},true,1).then(res=>{
            fn(res.data)
        })
    }
    setWebSite (param) {
        $post("setWebsite",param).then(res=>{
            tool.message('保存成功')
        })
    }
    saveWebSite(param){
        $post('addOrEditWebSite', param,true, 1).then(() => {
            tool.message('保存成功')
        })
    }
    saveSiteSet(param){
        $post('setSiteSet', param,true, 1).then(() => {
            tool.message('保存成功')
        })
    }

    /**
     * 获取saas端设置信息
     * @param {Array} keys 要获取的值的key
     * @returns
     */
    getSiteSet(keys,fn){
        $post('getSiteSet',{keys},false,1).then(res=>{
            let result = tool.filterForm(keys,res.data)
            fn(result)
        })
    }
    /**
     * 获取统计信息
     * @param {object} fn
     */
    getStatistics(fn) {
        $post("statistics",{},true,1).then(res=>{
            fn(res.data)
        })
    }
    /**
     * 获取全部的操作日志
     * @param {number} page
     * @param {number} limit
     * @param {number} user_id 当前登录用户的user_id
     * @param {object} fn
     */
    getSaasLog(page,limit,user_id,fn){
        let param = { page,limit }
        if( user_id ){
            param.user_id = user_id
        }
        common.getDataList("operationlog",param,true,1).then(res=>{
            fn(res);
        })
    }

    /**
     * 获取当前系统版本信息
     * @param {object}} fn
     */
    getCurrentVersinInfo(msg=false,fn){
        $post("getSysUpdateVersion",{},msg,1).then(res=>{
            fn(res.data)
        }).catch(()=>{})
    }

    /**
     * 获取saas端设置
     * @param {array} keys 需要的字段
     * @param {function} callback
     */
    getWebConfigSite(keys=null,callback){
        $post("getWebConfigSite",{},true,1).then(res=>{
            if( keys ){
                callback( tool.filterForm(keys,res.data) )
                return
            }
            callback(res.data)
        })
    }

    /**
     * 保存saas端配置信息
     * @param {object} param
     */
    saveWebConfigSite(param){
        $post("addOrEditWebSiteConfig",param,true,1).then(()=>{
            tool.message("保存成功");
        })
    }

    /**
     * 获取七牛key和密钥
     * @param {function} callback
     */
    getQiniuKeyAndSc(callback){
        $post('getKeyAndsecret',{},true,1).then(res=>callback(res))
    }

    /**
     * 获取阿里云buket
     * @param {string} cloud_aliyun_accesskey
     * @param {string} cloud_aliyun_secret
     * @param {function} callback
     */
    getAliBuket(cloud_aliyun_accesskey,cloud_aliyun_secret,callback){
        $post("getAliBucket",
            { cloud_aliyun_accesskey, cloud_aliyun_secret },
            false,1
        ).then(res=>callback(res)).catch(err=>callback(err))
    }

    /**
     * 获取七牛云buket列表
     * @param {number} page
     * @param {number} limit
     * @param {object} extra
     * @param {function} cb
     */
    getQiniuBuketList(page,limit,extra,cb){
        let param = { page,limit }
        if( extra && extra.pid ) param.pid = extra.pid
        $post('getBucketList', param,false,1).then(res=>cb(res)).catch(err=>cb(err))
    }

    /**
     * 创建七牛云空间
     * @param {object} param
     * @param {function} cb
     */
    createQiniuBuket(param,cb){
        if( !tool.returnMessage(param.bucket,"请输入空间名称")) return
        if( !tool.returnMessage(param.zone,'请选择地区')) return

        $post('createBucket', param,true, 1).then(res => {
            tool.message('保存成功')
            cb(res.data)
        })
    }

    /**
     * 获取通讯状态
     * @param {function} cb
     */
    getImStatus(cb){
        $post('getIMStatus', {}, true,1).then(res =>cb(res.data))
    }

    /**
     * 操作通讯状态
     * @param {number} type 1=启动 2停止 3重启
     * @param {function} cb
     */
    handleImStatus(type,cb) {
        let api = 'setIMStart';
        if (type == 2) api = 'setIMStop';
        if (type == 3) api = 'setIMRestart';
        $post(api, {},true, 1).then(res => {
            tool.message('操作成功');
            cb(res.data)
        });
    }

    /**
     * 获取队列状态
     * @param {function} cb
     */
    getQueueStatus(cb){
        $post('getQueueStatus', {}, true,1).then(res =>cb(res.data))
    }

    /**
     * 队列关闭或启动
     * @param {string} type start=启动  close=关闭
     */
    handleQueue(type){
        let api = ''
        if( type == 'start' ) api = 'startqueue'
        if( type == 'close' ) api = 'closeQueue'
        $post(api, {}, true,1).then(() => {
            tool.message( type == 'close' ?'队列已关闭' : '队列已启动');
        })
    }

    /**
     * 获取默认商品数据
     * @param page
     * @param limit
     * @param extra
     * @param cb
     */
    getDefaultData(page,limit,extra,cb){
        let param = { page,limit }
        if( extra && extra.type ) param.class = extra.type

        //获取全部的数据
        if( extra && extra.scene == 'all' ){
            $post("getSiteDefaultList",{server_id:extra.server_id},true,1).then(res=>cb(res.data))
            return
        }
        $post("getSiteDefaultConfig",param,true,1).then(res=>cb(res.data))
    }

    /**
     * 设置默认商品数据
     * @param scene     场景 index首页 goods商品 adopt认养 land土地 delete 删除数据
     * @param param     商品信息
     * @param id        编辑时需要
     * @param cb
     */
    setDefaultData(scene,param,id,cb){
        //删除数据
        if( scene == 'delete' ){
            let data ={ id,status:0 }
            $post("setSiteDefaultConfig",data,true,1).then(res=>{
                tool.message("删除成功")
                cb(res.data)
            })
            return
        }
        let name = ''
        if( scene == 'index' || scene == 'adopt' ) name = param.name
        if( scene == 'land' ) name = param.land_name
        if( scene == 'goods' ) name = param.goods_name


        //新增数据
        let data = {
            "name": name,
            "content": scene == 'index' ? param.content : JSON.stringify(param),
            "class": scene,
        }
        if( id ) data.id = id

        $post("setSiteDefaultConfig",data,true,1).then(res=>{
            tool.message("操作成功")
            cb(res.data)
        })
    }

}

const saasSetModel = new SaasSet()
export default saasSetModel
